
import React from 'react'
import { useRecoilState } from 'recoil'
import { getAuth, signOut } from 'firebase/auth'

import Meta from 'layout/Meta'
import firebase from 'utils/firebase'
import Spinner from 'utils/Spinner'
import useStatus from 'utils/useStatus'

import { userAtom } from 'utils/atoms'


export default function SignOutPage() {
	
	const [status, setStatus] = useStatus()
	const [user, setUser] = useRecoilState(userAtom)
	// const isAccountDeleted = router.query?.deleted === 'yes'

	React.useEffect(() => {
		const doSignOut = async () => {
			try {
				setStatus({ working: "Signing out" })
				const auth = getAuth(firebase)
				await signOut(auth)
				setUser({ isAuth: false, uid: null })
				setStatus({ success: true })
			} catch (error) { setStatus({ error }) }
		}
		doSignOut()
	}, [setUser, setStatus, user.isAnonymous])

	return <main>

		<Meta title="Bye" />

		{ status.working && <Spinner>{ status.working }</Spinner> }

		{ status.success && <h1>Thank you for visiting.</h1> }

		{ status.error }

	</main>
}
